class BoundingBox
  constructor: (@x_max, @x_min, @y_max, @y_min, @z_max, @z_min) ->
    @x_width = @x_max - @x_min
    @y_width = @y_max - @y_min
    @z_width = @z_max - @z_min


  contains: (boundingBox) ->
    return false  if boundingBox.x_min > @x_max or boundingBox.x_max < @x_min
    return false  if boundingBox.y_min > @y_max or boundingBox.y_max < @y_min
    return false  if boundingBox.z_min > @z_max or boundingBox.z_max < @z_min
    true

  intersects: (ray) ->
    o = ray.line.anchor
    d = ray.line.direction
    t_x1 = (@x_min - o.e(1)) / d.e(1)
    t_y1 = (@y_min - o.e(2)) / d.e(2)
    t_z1 = (@z_min - o.e(3)) / d.e(3)
    t_x2 = (@x_max - o.e(1)) / d.e(1)
    t_y2 = (@y_max - o.e(2)) / d.e(2)
    t_z2 = (@z_max - o.e(3)) / d.e(3)
    t_x_min = Math.min(t_x1, t_x2)
    t_x_max = Math.max(t_x1, t_x2)
    t_y_min = Math.min(t_y1, t_y2)
    t_y_max = Math.max(t_y1, t_y2)
    t_z_min = Math.min(t_z1, t_z2)
    t_z_max = Math.max(t_z1, t_z2)
    t_min = Math.max(t_x_min, t_y_min, t_z_min)
    t_max = Math.min(t_x_max, t_y_max, t_z_max)

    # intersection if t_min < t_max
    t_min < t_max

  @getBoundingBoxFromObjects: (objects) ->
    x_min = Infinity
    y_min = Infinity
    z_min = Infinity
    x_max = -Infinity
    y_max = -Infinity
    z_max = -Infinity
    i = 0

    while i < objects.length
      if objects[i].getBoundingBox
        boundingBox = objects[i].getBoundingBox()
        x_min = boundingBox.x_min if boundingBox.x_min < x_min
        y_min = boundingBox.y_min if boundingBox.y_min < y_min
        z_min = boundingBox.z_min if boundingBox.z_min < z_min
        x_max = boundingBox.x_max if boundingBox.x_max > x_max
        y_max = boundingBox.y_max if boundingBox.y_max > y_max
        z_max = boundingBox.z_max if boundingBox.z_max > z_max
      else
        x_min = Number.MIN_VALUE
        y_min = Number.MIN_VALUE
        z_min = Number.MIN_VALUE
        x_max = Number.MAX_VALUE
        y_max = Number.MAX_VALUE
        z_max = Number.MAX_VALUE
      i++
    new BoundingBox(x_max, x_min, y_max, y_min, z_max, z_min)

class Camera
  constructor: (@position, @direction, @upDirection, @distance, @fieldOfView, @width, @height) ->
    this.calibrateCamera()

  calibrateCamera: () ->
    @direction = @direction.toUnitVector()
    @rightDirection = @direction.cross(@upDirection)
    @imagePaneHeight = 2 * Math.tan(@fieldOfView / 2) * @distance
    @imagePaneWidth = @imagePaneHeight / @height * @width
    @imageCenter = @position.add(@direction.multiply(@distance))

class Color
  @fromVector: (vec) ->
    new Color(vec.e(1), vec.e(2), vec.e(3))
  @random: () ->
    new Color(Math.random(), Math.random(), Math.random())
  constructor: (r, g, b) ->
    if r instanceof Vector
      g = r.elements[1]
      b = r.elements[2]
      r = r.elements[0]
    r = 0 if r < 0
    g = 0 if g < 0
    b = 0 if b < 0
    r = 1 if r > 1
    g = 1 if g > 1
    b = 1 if b > 1
    @val = $V([r, g, b])
  add: (color) ->
    new Color(@val.add(color.val))
  multiply: (scale) ->
    new Color(@val.multiply(scale))
  multiplyColor: (color) ->
    new Color(@val.elements[0] * color.val.elements[0], @val.elements[1] * color.val.elements[1],
      @val.elements[2] * color.val.elements[2])
  toArray: ->
    @val.dup().elements
  toVector: ->
    @val.dup()

class Cylinder
  constructor: (@axis_line, @fixed_x, @fixed_y, @fixed_z, @radius_x, @radius_y, @radius_z, @reflectionProperties) ->
    @radius_x_2 = Math.square(@radius_x)
    @radius_y_2 = Math.square(@radius_y)
    @radius_z_2 = Math.square(@radius_z)

  norm: (intersectionPoint, ray) ->
    intersection = $V([((if @fixed_x then 0 else (intersectionPoint.e(1)) / @radius_x_2)),
              ((if @fixed_y then 0 else (intersectionPoint.e(2)) / @radius_y_2)),
              ((if @fixed_z then 0 else (intersectionPoint.e(3)) / @radius_z_2))])
    n = intersection.subtract(@axis_line)
    n.toUnitVector()

  solutions: (ray) ->
    oc = ray.line.anchor.subtract(@axis_line)
    dir = ray.line.direction.toUnitVector()

    a = ((if @fixed_x then 0 else ((dir.e(1) * dir.e(1)) / @radius_x_2))) +
    ((if @fixed_y then 0 else (dir.e(2) * dir.e(2) / @radius_y_2))) +
    ((if @fixed_z then 0 else dir.e(3) * dir.e(3) / @radius_z_2))

    b = ((if @fixed_x then 0 else ((2 * oc.e(1) * dir.e(1)) / @radius_x_2))) +
    ((if @fixed_y then 0 else ((2 * oc.e(2) * dir.e(2)) / @radius_y_2))) +
    ((if @fixed_z then 0 else ((2 * oc.e(3) * dir.e(3)) / @radius_z_2)))

    c = ((if @fixed_x then 0 else ((oc.e(1) * oc.e(1)) / @radius_x_2))) +
    ((if @fixed_y then 0 else ((oc.e(2) * oc.e(2)) / @radius_y_2))) +
    ((if @fixed_z then 0 else ((oc.e(3) * oc.e(3)) / @radius_z_2))) - 1

    under_root = (Math.square(b) - (4 * a * c))
    return null if under_root < 0 || a == 0 || b == 0 || c == 0

    root = Math.sqrt(under_root)
    t1 = (-b + root) / (2 * a)
    t2 = (-b - root) / (2 * a)
    return t2  if t1 < RayConfig.intersectionDelta
    return t1  if t2 < RayConfig.intersectionDelta

    # returns the smaller ti first
    if t1 <= t2
      return [t1, t2]
    return [t2, t1]

  intersection: (ray) ->
    i = this.solutions(ray)
    return null unless i
    [t1, t2] = i
    new Intersection(ray, this, this, t1, t2, @reflectionProperties)

# From: http://cudaopencl.blogspot.ch/2012/12/ellipsoids-finally-added-to-ray-tracing.html#

class Ellipsoid
  constructor: (@center, @radius_x, @radius_y, @radius_z, @reflectionProperties) ->
    @radius_x_2 = Math.square @radius_x
    @radius_y_2 = Math.square @radius_y
    @radius_z_2 = Math.square @radius_z

  norm: (intersectionPoint, ray) ->
    # This is the naive way:
    # zz = intersectionPoint.subtract(@center)
    # nx = 2 * zz.e(1) / @radius_x_2
    # ny = 2 * zz.e(2) / @radius_y_2
    # nz = 2 * zz.e(3) / @radius_z_2
    # return $V([nx, ny, nz]).toUnitVector()

    # And this is the right way
    n = intersectionPoint.subtract(@center)
    t = $M([
      [2 / @radius_x_2, 0, 0],
      [0, 2 / @radius_y_2, 0],
      [0, 0, 2 / @radius_z_2]
    ])
    n = t.multiply(n)
    n.toUnitVector()

  solutions: (ray) ->
    oc = ray.line.anchor.subtract(@center)
    dir = ray.line.direction.toUnitVector()
    a = ((dir.e(1) * dir.e(1)) / @radius_x_2) +
    ((dir.e(2) * dir.e(2)) / @radius_y_2) +
    ((dir.e(3) * dir.e(3)) / @radius_z_2)
    b = ((2 * oc.e(1) * dir.e(1)) / @radius_x_2) +
    ((2 * oc.e(2) * dir.e(2)) / @radius_y_2) +
    ((2 * oc.e(3) * dir.e(3)) / @radius_z_2)
    c = ((oc.e(1) * oc.e(1)) / @radius_x_2) +
    ((oc.e(2) * oc.e(2)) / @radius_y_2) +
    ((oc.e(3) * oc.e(3)) / @radius_z_2) - 1

    Math.solveN2(a, b, c)

  intersection: (ray) ->
    i = this.solutions(ray)
    return null unless i
    [t1, t2] = i
    new Intersection(ray, this, this, t1, t2, @reflectionProperties)

class Hemisphere

  constructor: (@sphere, @plane) ->

  intersection: (ray) ->
    s = @sphere.intersection(ray)
    p = @plane.intersection(ray)

    # one intersection
    return null unless s && p

    s1 = s.distance
    s2 = s.distance2
    p1 = p.distance

    # sphere intersection before plane intersection
    return null if s1 < p1 && s2 < p1

    # plane intersection before sphere intersection
    return s if s1 > p1 && s2 > p1

    # plane intersection between sphere intersections
    return p if s1 < p1 && s2 > p1

    throw "Invalid state: s1: #{s2}, s1: #{s2}, p1: #{p1}"

  #solutions: (ray) ->
  #  i = this.intersection(ray)
  #  return null unless i
  #  [i.t1, i.t2]

class Mesh

  constructor: (@position, @scale) ->
    @V = new Array() # vertices
    @F = new Array() # triangles
    @N = new Array() # normals
    @triangles = new Array()
    @octree = new Octree(null, 0)

  getBoundingBox: ->
    @boundingBox = BoundingBox.getBoundingBoxFromObjects(@triangles) unless @boundingBox
    @boundingBox

  generateTriangles: ->
    i = 0
    while i < @F.length
      face = @F[i]
      triangle = new Triangle(@V[face.e(1)].multiply(@scale).add(@position),
        @V[face.e(2)].multiply(@scale).add(@position), @V[face.e(3)].multiply(@scale).add(@position), null)
      @triangles[i] = triangle
      i++

    if RayConfig.octree
      depth = Math.min(Math.ceil(Math.log(@triangles.length) / Math.log(8)), RayConfig.octreeMaxDepth)
      @octree = new Octree(this.getBoundingBox(), depth)
      i = 0

      while i < @triangles.length
        @octree.insertObject @triangles[i]
        i++

  intersection: (ray) ->
    min_intersection = null
    objects = (if RayConfig.octree then @octree.getIntersectionObjects(ray) else @triangles)

    #console.rlog("triangle intersection tests (before): " + objects.length);
    objects = objects.filter((elem, pos) ->
      objects.indexOf(elem) is pos
    )

    #console.rlog("triangle intersection tests (after): " + objects.length);
    i = 0
    while i < objects.length
      intersection = objects[i].intersection(ray)
      if intersection
        if min_intersection == null || intersection.distance < min_intersection.distance
          min_intersection = intersection
          min_intersection.reflectionProperties = @reflectionProperties
          min_intersection.figure = this
      ++i
    min_intersection

  computeNormals: ->
    i = 0
    while i < @V.length
      @N[i] = $V([0, 0, 0])
      ++i

    i = 0
    while i < @F.length
      f = @F[i]
      t = @triangles[i]
      @N[f.e(1)] = @N[f.e(1)].add(t.getTriangleNormal().multiply(t.getArea()))
      @N[f.e(2)] = @N[f.e(2)].add(t.getTriangleNormal().multiply(t.getArea()))
      @N[f.e(3)] = @N[f.e(3)].add(t.getTriangleNormal().multiply(t.getArea()))
      i++

    i = 0
    while i < @V.length
      @N[i] = @N[i].toUnitVector()
      ++i

    i = 0
    while i < @triangles.length
      f = @F[i]
      t = @triangles[i]
      t.n1 = @N[f.e(1)]
      t.n2 = @N[f.e(2)]
      t.n3 = @N[f.e(3)]
      i++
class MultipleObjectsIntersection
  constructor: (@figure1, @figure2, @reflectionProperties) ->

  norm: (intersectionPoint, ray) ->
    i1 = @figure1.solutions(ray)
    i2 = @figure2.solutions(ray)

    [i11, i12] = i1
    [i11, i12] = [i12, i11] if i11 > i12
    [i21, i22] = i2
    [i21, i22] = [i22, i21] if i21 > i22

    f = if i21 < i11 then @figure1 else @figure2

    f.norm(intersectionPoint, ray)

  solutions: (ray) ->
    i = this.intersection(ray)
    return null unless i
    [i.t1, i.t2]

  intersection: (ray) ->
    f1 = @figure1
    f2 = @figure2
    i1 = f1.solutions(ray)
    i2 = f2.solutions(ray)

    return null unless i1 && i2

    [i11, i12] = i1
    [i11, i12] = [i12, i11] if i11 > i12

    [i21, i22] = i2
    [i21, i22] = [i22, i21] if i21 > i22

    [f1, i1, i11, i12, f2, i2, i21, i22] = [f2, i2, i21, i22, f1, i1, i11, i12] if i21 < i11

    return null if i12 <= i21

    new Intersection(ray, this, f2, i21, Math.min(i12, i22), @reflectionProperties)

class Plane
  constructor: (@point, @normal, @reflectionProperties) ->
    @normal = @normal.toUnitVector()

  norm: (intersectionPoint, ray) ->
    @normal

  intersection: (ray) ->
    c = ray.line.direction.dot(@normal)

    # the angle is zero
    return null if c == 0

    distance = @point.subtract(ray.line.anchor).dot(@normal) / c
    return null if distance < RayConfig.intersectionDelta

    epsilon = 0.01
    new Intersection(ray, this, this, distance, 0, @reflectionProperties)

  solutions: (ray) ->
    i = this.intersection(ray)
    return null unless i
    [i.t1, i.t2]

class Sphere
  constructor: (@center, @radius, @reflectionProperties, @texture, @normalmap) ->
    @radiusSquared = @radius * @radius
    @boundingBoxCache = new BoundingBox(@center.e(1) + @radius, @center.e(1) - @radius, @center.e(2) + @radius, @center.e(2) - @radius, @center.e(3) + @radius, @center.e(3) - @radius)

    @northDirection = $V([0, 1, 1]).toUnitVector()
    @meridianDirection = $V([-1, 1, -1]).toUnitVector()

  norm: (intersectionPoint, ray) ->
    intersectionPoint.subtract(@center).toUnitVector()

  boundingBox: () ->
    @boundingBoxCache
  getBoundingBox: () ->
    @boundingBoxCache

  isLeft: (plane) ->
    @boundingBoxCache.right >= plane.point.e(1)
  isRight: (plane) ->
    @boundingBoxCache.left <= plane.point.e(1)
  isTop: (plane) ->
    @boundingBoxCache.top >= plane.point.e(2)
  isBottom: (plane) ->
    @boundingBoxCache.bottom <= plane.point.e(2)
  isBack: (plane) ->
    @boundingBoxCache.back >= plane.point.e(3)
  isFront: (plane) ->
    @boundingBoxCache.front <= plane.point.e(3)

  solutions: (ray) ->
    o = ray.line.anchor
    d = ray.line.direction
    c = @center

    # c-o
    c_minus_o = c.subtract(o)
    #console.rlog "c_minus_o:"
    #console.rlog c_minus_o

    # ||c-o||^2
    distSquared = c_minus_o.dot(c_minus_o)
    #console.rlog "distSquared=" + distSquared

    # (c-o)*d
    rayDistanceClosestToCenter = c_minus_o.dot(d)
    #console.rlog "rayDistanceClosestToCenter=" + rayDistanceClosestToCenter
    return false if rayDistanceClosestToCenter < 0

    # D^2 = ||c-o||^2 - ((c-o)*d)^2
    shortestDistanceFromCenterToRaySquared = distSquared - (rayDistanceClosestToCenter * rayDistanceClosestToCenter)
    #console.rlog "shortestDistanceFromCenterToRay=" + shortestDistanceFromCenterToRaySquared
    #console.rlog "@radiusSquared=" + @radiusSquared
    return false if shortestDistanceFromCenterToRaySquared > @radiusSquared

    # t = (o-c)*d ± sqrt(r^2 - D^2)
    x = @radiusSquared - shortestDistanceFromCenterToRaySquared
    return null if x < 0
    t1 = rayDistanceClosestToCenter - Math.sqrt(x)
    t2 = rayDistanceClosestToCenter + Math.sqrt(x)
    return [t2, t2] if t1 < RayConfig.intersectionDelta
    return [t1, t1] if t2 < RayConfig.intersectionDelta
    [t1, t2]

  intersection: (ray) ->
    i = this.solutions(ray)
    return null unless i
    [t1, t2] = i
    new Intersection(ray, this, this, t1, t2, @reflectionProperties)

  getInclination: (unitVector) ->
    x = unitVector.e(1)
    y = unitVector.e(2)
    z = unitVector.e(3)
    Math.acos y

  getAzimuth: (unitVector) ->
    x = unitVector.e(1)
    y = unitVector.e(2)
    z = unitVector.e(3)
    azimuth = Math.atan(x / z)
    if z < 0
      azimuth += Math.PI
    else azimuth += Math.PI * 2  if x < 0
    azimuth

  calcUV: (intersectionPoint) ->
    center_to_point = intersectionPoint.subtract(@center).toUnitVector()
    origin = $V([0, 0, 0])
    rightDirection = $V([1, 0, 0])
    upDirection = $V([0, 1, 0])
    frontDirection = $V([0, 0, 1])
    meridianAngle = -Math.acos(@meridianDirection.dot(frontDirection))
    center_to_point = center_to_point.rotate(meridianAngle, $L($V([0, 0, 0]), upDirection))
    upDirection = upDirection.rotate(meridianAngle, $L(origin, upDirection))
    rightDirection = rightDirection.rotate(meridianAngle, $L(origin, upDirection))
    frontDirection = frontDirection.rotate(meridianAngle, $L(origin, upDirection))
    northAngle = -Math.acos(@northDirection.dot(upDirection))
    center_to_point = center_to_point.rotate(northAngle, $L(origin, rightDirection))
    upDirection = upDirection.rotate(northAngle, $L(origin, rightDirection))
    rightDirection = rightDirection.rotate(northAngle, $L(origin, rightDirection))
    frontDirection = frontDirection.rotate(northAngle, $L(origin, rightDirection))
    inclination = @getInclination(center_to_point)
    azimuth = @getAzimuth(center_to_point)
    u = azimuth / (2 * Math.PI)
    v = -(inclination / Math.PI) + 1
    [u, v]
class Triangle
  constructor: (@v1, @v2, @v3, @reflectionProperties) ->

  getBoundingBox: ->
    return @boundingBox if @boundingBox
    min_x = Infinity
    min_y = Infinity
    min_z = Infinity
    max_x = -Infinity
    max_y = -Infinity
    max_z = -Infinity
    min_x = @v1.e(1) if @v1.e(1) < min_x
    min_x = @v2.e(1) if @v2.e(1) < min_x
    min_x = @v3.e(1) if @v3.e(1) < min_x
    max_x = @v1.e(1) if @v1.e(1) > max_x
    max_x = @v2.e(1) if @v2.e(1) > max_x
    max_x = @v3.e(1) if @v3.e(1) > max_x
    min_y = @v1.e(2) if @v1.e(2) < min_y
    min_y = @v2.e(2) if @v2.e(2) < min_y
    min_y = @v3.e(2) if @v3.e(2) < min_y
    max_y = @v1.e(2) if @v1.e(2) > max_y
    max_y = @v2.e(2) if @v2.e(2) > max_y
    max_y = @v3.e(2) if @v3.e(2) > max_y
    min_z = @v1.e(3) if @v1.e(3) < min_z
    min_z = @v2.e(3) if @v2.e(3) < min_z
    min_z = @v3.e(3) if @v3.e(3) < min_z
    max_z = @v1.e(3) if @v1.e(3) > max_z
    max_z = @v2.e(3) if @v2.e(3) > max_z
    max_z = @v3.e(3) if @v3.e(3) > max_z
    @boundingBox = new BoundingBox(max_x, min_x, max_y, min_y, max_z, min_z)
    @boundingBox

  getArea: ->
    return @area if @area

    AB = @v2.subtract(@v1)
    AC = @v3.subtract(@v1)
    cr = AB.cross(AC)
    @area = cr.distanceFrom($V([0, 0, 0])) / 2
    @area

  getTriangleNormal: ->
    return @triangleNormal if @triangleNormal

    e1 = @v1.subtract(@v2)
    e2 = @v1.subtract(@v3)
    @triangleNormal = e1.cross(e2).toUnitVector()
    @triangleNormal

  norm: (intersectionPoint) ->
    return this.getTriangleNormal() unless @n1 || @n2 || @n3
    t1 = new Triangle(intersectionPoint, @v2, @v3).getArea()
    t2 = new Triangle(intersectionPoint, @v1, @v3).getArea()
    t3 = new Triangle(intersectionPoint, @v1, @v2).getArea()

    normal = $V([0, 0, 0])
    normal = normal.add(@n1.multiply(t1))
    normal = normal.add(@n2.multiply(t2))
    normal = normal.add(@n3.multiply(t3))
    normal.toUnitVector()

  intersection: (ray) ->

    # ⟨Get triangle vertices in p1, p2, and p3 140⟩
    p1 = @v1
    p2 = @v2
    p3 = @v3

    # ⟨Compute s1 141⟩
    e1 = p2.subtract(p1)
    e2 = p3.subtract(p1)
    s1 = ray.line.direction.cross(e2)
    divisor = s1.dot(e1)
    return null if divisor is 0
    invDivisor = 1.0 / divisor

    # ⟨Compute first barycentric coordinate 142⟩
    d = ray.line.anchor.subtract(p1)
    b1 = d.dot(s1) * invDivisor
    return null if b1 < 0 or b1 > 1

    # ⟨Compute second barycentric coordinate 142⟩
    s2 = d.cross(e1)
    b2 = ray.line.direction.dot(s2) * invDivisor
    return null if b2 < 0 or b1 + b2 > 1

    # ⟨Compute t to intersection point 142⟩
    t = e2.dot(s2) * invDivisor


    #if (t < ray.mint || t > ray.maxt)
    #    return false;

    # ⟨Compute triangle partial derivatives 143⟩
    # ⟨Interpolate (u, v) triangle parametric coordinates 143⟩
    # ⟨Test intersection against alpha texture, if present 144⟩
    # ⟨Fill in Differential Geometry from triangle hit 145⟩
    # *tHit = t;
    # *rayEpsilon = 1e-3f * *tHit;
    # return true;
    i = new Intersection(ray, this, this, t, null, @reflectionProperties)

### Random log ###
console.setRlog = (p = 0.0001) ->
  @shoulLog = Math.random() <= p
console.rlog = (msg) ->
  return unless @shoulLog
  console.log(msg)

Math.square = (num) -> num * num

Math.solveN2 = (a, b, c) ->
  under_root = ((b * b) - (4 * a * c))
  return null if under_root < 0 or a is 0 or b is 0 # or c is 0

  root = Math.sqrt(under_root)
  t1 = (-b + root) / (2 * a)
  t2 = (-b - root) / (2 * a)
  return [t2, t2] if t1 < RayConfig.intersectionDelta
  return [t1, t1] if t2 < RayConfig.intersectionDelta
  [t1, t2]

class Intersection
  constructor: (@ray, @figure, @normalFigure, @t1, @t2, @reflectionProperties) ->
    @t1 = 0 if -RayConfig.intersectionDelta < @t1 < RayConfig.intersectionDelta
    @t2 = 0 if -RayConfig.intersectionDelta < @t2 < RayConfig.intersectionDelta

    if @t1 > 0 && @t2 > 0
      @distance = Math.min(@t1, @t2)
      @distance2 = Math.max(@t1, @t2)
    else if @t1 > 0 && @t2 <= 0
      @distance = @t1
      @distance2 = @t2
    else if @t2 > 0 && @t1 <= 0
      @distance = @t2
      @distance2 = @t2

  getNormal: () ->
    unless @normal
      if RayConfig.normalmap && @figure.normalmap
        old_normal = @normalFigure.norm(this.getPoint())

        tangent1 = old_normal.cross($V([0, 1, 0])).toUnitVector()
        tangent2 = old_normal.cross(tangent1).toUnitVector()
        transMatrix = $M([tangent1.multiply(-1).elements, tangent2.multiply(-1).elements, old_normal.elements])
        transMatrix = transMatrix.transpose()
        uv = @figure.calcUV(@getPoint())
        new_normal = @figure.normalmap.getNormal(uv[0], uv[1])

        @normal = transMatrix.multiply(new_normal)
      else
        @normal = @normalFigure.norm(this.getPoint(), @ray)
    @normal

  getPoint: () ->
    @point = @ray.line.anchor.add(@ray.line.direction.multiply(@distance)) unless @point
    @point

  getAmbientColor: () ->
    if RayConfig.texture and @figure.texture
      uv = @figure.calcUV(@getPoint())
      return @figure.texture.getPixelColor(uv[0], uv[1])
    @figure.reflectionProperties.ambientColor

  getSpecularColor: () ->
    if RayConfig.texture and @figure.texture
      uv = @figure.calcUV(@getPoint())
      return @figure.texture.getPixelColor(uv[0], uv[1])
    @figure.reflectionProperties.specularColor

  getDiffuseColor: () ->
    if RayConfig.texture and @figure.texture
      uv = @figure.calcUV(@getPoint())
      return @figure.texture.getPixelColor(uv[0], uv[1])
    @figure.reflectionProperties.diffuseColor

class Light
  constructor: (@location, @color, @intensity, @radius, @direction) ->
    if @radius
      @upDirection = @direction.cross($V([1, 0, 0]))
      @rightDirection = @direction.cross(@upDirection)

  calculateShadowRatio: (p, wl, ray, scene) ->
    if @radius && ModuleId.D2
      # Area light
      return this.calculateAreaLight(p, wl, ray, scene)
    else
      return if scene.firstIntersection(new Ray($L(p, wl), ray.refraction, 1, ray.eye)) then 1 else 0


  calculateAreaLight: (p, wl, ray, scene) ->
    # Use jitter for monte carlo integration
    cellSize = @radius * 2 / RayConfig.areaLightShadowsAxis

    shadowIntensity = 0
    i = 0
    while i < RayConfig.areaLightShadows
      r = -RayConfig.areaLightShadowsAxis / 2
      while r < RayConfig.areaLightShadowsAxis / 2
        s = -RayConfig.areaLightShadowsAxis / 2
        while s < RayConfig.areaLightShadowsAxis / 2
          newP = null
          z = 0
          loop
            z++
            x = r * cellSize + cellSize * Math.random()
            y = s * cellSize + cellSize * Math.random()
            newP = @location.add(@rightDirection.multiply(x)).add(@upDirection.multiply(y))
            break if newP.distanceFrom(@location) <= @radius
            if z > 20
              newP = null
              break
          if newP
            wl = newP.subtract(p).toUnitVector();
            light_intersection = scene.firstIntersection(new Ray($L(p, wl), ray.refraction, 1, ray.eye))
            shadowIntensity += 1 / RayConfig.areaLightShadows if light_intersection
          i++
          s++
        r++
    shadowIntensity

class LightIntensity
  constructor: (@ambient, @diffuse, @specular)->

class MeshLoader
  constructor: (@resp, @position, @scale) ->
    @obj = new Mesh(@position, @scale)

  createMesh: () ->
    this.loadMesh(@obj, @resp)
    @obj.generateTriangles()
    @obj.computeNormals()
    @obj

  loadMesh: (mesh, data) ->
    # v float float float
    vertex_pattern = /v( +[\d|\.|\+|\-|e]+)( +[\d|\.|\+|\-|e]+)( +[\d|\.|\+|\-|e]+)/
    # f vertex vertex vertex
    face_pattern1 = /f( +\d+)( +\d+)( +\d+)/
    # f vertex/uv vertex/uv vertex/uv
    face_pattern2 = /f( +(\d+)\/(\d+))( +(\d+)\/(\d+))( +(\d+)\/(\d+))/
    # f vertex/uv/normal vertex/uv/normal vertex/uv/normal
    face_pattern3 = /f( +(\d+)\/(\d+)\/(\d+))( +(\d+)\/(\d+)\/(\d+))( +(\d+)\/(\d+)\/(\d+))/
    # f vertex//normal vertex//normal vertex//normal
    face_pattern4 = /f( +(\d+)\/\/(\d+))( +(\d+)\/\/(\d+))( +(\d+)\/\/(\d+))/

    lines = data.split("\n")
    i = 0
    while i < lines.length
      line = lines[i]
      line = line.trim()
      result = undefined
      if line.length == 0 || line.charAt(0) == "#" || line.charAt( 0 ) == '//'
        i++
        continue
      else if (result = vertex_pattern.exec(line)) isnt null
        # ["v 1.0 2.0 3.0", "1.0", "2.0", "3.0"]
        mesh.V.push $V([parseFloat(result[1]), parseFloat(result[2]), parseFloat(result[3])])
      else if (result = face_pattern1.exec(line)) isnt null
        # ["f 1 2 3", "1", "2", "3"]
        mesh.F.push $V([parseInt(result[1]) - 1, parseInt(result[2]) - 1, parseInt(result[3]) - 1])
      else if (result = face_pattern2.exec(line)) isnt null
        # ["f 1/1 2/2 3/3", " 1/1", "1", "1", " 2/2", "2", "2", " 3/3", "3", "3"]
        mesh.F.push $V([parseInt(result[2]) - 1, parseInt(result[5]) - 1, parseInt(result[8]) - 1])
      else if (result = face_pattern3.exec(line)) isnt null
        # ["f 1/1/1 2/2/2 3/3/3", " 1/1/1", "1", "1", "1", " 2/2/2", "2", "2", "2", " 3/3/3", "3", "3", "3"]
        mesh.F.push $V([parseInt(result[2]) - 1, parseInt(result[6]) - 1, parseInt(result[10]) - 1])
        # ["f 1//1 2//2 3//3", " 1//1", "1", "1", " 2//2", "2", "2", " 3//3", "3", "3"]
      else mesh.F.push $V([parseInt(result[2]) - 1, parseInt(result[5]) - 1, parseInt(result[8]) - 1])  if (result = face_pattern4.exec(line)) isnt null
      i++

  @loadMeshData: (path) ->
    console.log "Reading OBJ file: " + path
    req = new XMLHttpRequest()
    req.open "GET", path, false
    req.send null
    req.response

class NormalMap
  constructor: (_normalmap) ->
    @tga = readTGA(_normalmap)

  getNormal: (u, v) ->
    # exact pixel values
    u = u * @tga.header.width
    v = v * @tga.header.height

    # integer pixel values surrounding the exact value
    u1 = Math.floor(u)
    v1 = Math.floor(v)
    u2 = (u1 + 1)
    v2 = (v1 + 1)

    # fractional parts of u and v
    frac_u = u - Math.floor(u)
    frac_v = v - Math.floor(v)

    # weight factors of the surrounding pixels
    w1 = (1 - frac_u) * (1 - frac_v)
    w2 = frac_u * (1 - frac_v)
    w3 = (1 - frac_u) * frac_v
    w4 = frac_u * frac_v

    # weighted pixel colors of the surrounding pixels
    n1 = this.getPixelNormal(u1, v1).multiply(w1)
    n2 = this.getPixelNormal(u2, v1).multiply(w2)
    n3 = this.getPixelNormal(u1, v2).multiply(w3)
    n4 = this.getPixelNormal(u2, v2).multiply(w4)

    #/ add them together
    n1.add(n2).add(n3).add n4

  @earth: () ->
    new NormalMap("data/EarthNormal.tga")

  @moon: () ->
    new NormalMap("data/MoonNormal.tga")

  getPixelNormal: (u, v) ->
    id = 3 * (v * @tga.header.width + u)
    r = @tga.image[id + 2] / 255.0
    g = @tga.image[id + 1] / 255.0
    b = @tga.image[id + 0] / 255.0
    $V([2 * r - 1, 2 * g - 1, 2 * b - 1]).toUnitVector()

  noFilter: (u, v) ->
    fu = Math.floor(u * @tga.header.width)
    fv = Math.floor(v * @tga.header.height)
    this.getPixelNormal fu, fv

# Concept from: http://www.brandonpelfrey.com/blog/coding-a-simple-octree/

class Octree
  constructor: (@boundingBox, @depth) ->
    #@maxDepth = RayConfig.octreeMaxDepth
    @data = null
    @children = new Array()

  isLeaf: ->
    @children.length is 0

  makeChildren: ->
    x = 0
    while x <= 1
      y = 0
      while y <= 1
        z = 0
        while z <= 1
          new_b = new BoundingBox(@boundingBox.x_max - (1 - x) * @boundingBox.x_width / 2,
            @boundingBox.x_min + x * @boundingBox.x_width / 2,
            @boundingBox.y_max - (1 - y) * @boundingBox.y_width / 2,
            @boundingBox.y_min + y * @boundingBox.y_width / 2,
            @boundingBox.z_max - (1 - z) * @boundingBox.z_width / 2,
            @boundingBox.z_min + z * @boundingBox.z_width / 2)
          @children.push new Octree(new_b, @depth - 1)
          z++
        y++
      x++

  insertObject: (object) ->
    if @depth <= 0
      @data = new Array() if @data == null
      @data.push object
      return

    # The node is a leaf (no children/not split) and has no data assigned.
    if this.isLeaf() && @data == null

      # This is the easiest! We’ve ended up in a small region of space
      # with no data currently assigned and no children,
      # so we will simply assign this data point
      # to this leaf node and we’re done!
      @data = object
      return

    # The node is a leaf (no children/not split),
    # but it already has a point assigned.
    if @isLeaf() && @data != null

      # This is slightly more complicated.
      # We are at a leaf but there’s something already here.
      # Since we only store one point in a leaf, we will actually
      # need to remember what was here already, split this node
      # into eight children, and then re-insert the old point and
      # our new point into the new children.
      # Note: it’s entirely possible that this will happen several
      # times during insert if these two points are really close
      # to each other. (On the order of the logarithm of the space
      # separating them.)
      this.makeChildren()
      tmp = @data
      @data = null
      this.insertObject tmp

    # The node is an interior node to the tree (has 8 children).
    unless this.isLeaf()

      # Since we never store data in an interior node
      # of the tree in this article, we will find out
      # which of the eight children the data point
      # lies in and then make a recursive call to
      # insert into that child.
      objBoundingBox = object.getBoundingBox()
      i = 0

      while i < @children.length
        @children[i].insertObject object  if @children[i].boundingBox.contains(objBoundingBox)
        i++
      return

  getIntersectionObjects: (ray) ->
    if this.isLeaf()
      return @data if @data != null && @depth <= 0
      return [@data] if @data != null
      return []
    objects = new Array()
    i = 0

    while i < 8
      objects = objects.concat(@children[i].getIntersectionObjects(ray)) if @children[i].boundingBox.intersects(ray)
      i++
    objects
class Ray
  constructor: (@line, @refraction, @power, @eye) ->
  isInside: () ->
    @refraction != 1

this.ModuleId =
  B1: `undefined` #... specular reflection/refraction and recursive ray tracing
  B2: `undefined` #... anti-aliasing
  B3: `undefined` #... quadrics
  B4: `undefined` #... CSG primitives
  C1: `undefined` #... stereo
  C2: `undefined` #... texture mapping
  C3: `undefined` #... meshes
  D1: `undefined` #... octree
  D2: `undefined` #... area light
  ALT: `undefined` #... alternative scene
  SP1: `undefined` #... adds special objects to the scene

if document? && $?
  $(document).ready ->
    unless document.location.toString().indexOf("?") is -1
      query = document.location.toString().replace(/^.*?\?/, "").replace("#", "").split("&")
      query.forEach (q) ->
        tmp = q.split("=")
        k = tmp[0]
        v = tmp[1]
        if v is `undefined` or v is "1" or v is "true"
          v = true
        else
          v = false
        ModuleId[k] = v

    for k of ModuleId
      v = ModuleId[k]
      checkbox = document.createElement("input")
      checkbox.type = "checkbox"
      checkbox.value = 1
      checkbox.name = k
      checkbox.id = k
      checkbox.setAttribute "checked", "checked"  if v
      label = document.createElement("label")
      label.setAttribute "class", "btn btn-primary" + ((if v then " active" else ""))
      label.appendChild checkbox
      $(label).data "option", k
      label.innerHTML += k
      $("#renderOptions").append label

this.initRayConfig = () ->
  this.RayConfig =
    width: 800 #800
    height: 600 #600
    illumination: true
    shadow: true
    reflection: ModuleId.B1
    refraction: ModuleId.B1
    antialiasing: if ModuleId.B2 then 4 else 1 # set to 1 for no antialiasing
    antialiasingTechnique: 'grid' # options: grid, random, jittered
    recDepth: 2
    intersectionDelta: 0.00001
    strongRefraction: false
    octreeMaxDepth: 10000
    texture: true
    normalmap: true
    octree: true
    areaLightShadows: 50
    areaLightShadowsAxis: 8 # 8*8/4*pi => have 64 square boxes on a square, 50 of them will hit the disc

initRayConfig()

class RayTracer
  constructor: (@scene) ->

  trace: (pixelX, pixelY) ->
    # 1. shoot a ray determined from the camera parameters and the pixel position in the image
    # 2. intersect the ray to scene elements and determine the closest one
    # 3. check if the intersection point is illuminated by each light source
    # 4. shade the intersection point using the meterial attributes and the lightings
    # 5. set the pixel color into the image buffer using the computed shading (for now set dummy color into the image buffer)
    rays = this.castRays(RayConfig.antialiasing, pixelX, pixelY)
    console.setRlog()

    traceRay = (ray) =>
      this.traceRec(ray, RayConfig.recDepth)

    colors = {center: [], left: [], right: []}

    for ray in rays
      colors[ray.eye].push traceRay(ray)

    #colors = rays.map (ray) ->
    #  traceRay(ray)

    colorVectors = {}
    arr = if ModuleId.C1 then ['left', 'right'] else ['center']
    for eye in arr
      colorVectors[eye] = colors[eye].map((c) ->
        c.toVector()).reduce((previous, current) ->
        previous.add(current)).multiply(1 / colors[eye].length)

    if ModuleId.C1
      lm = $M([
        [0.3, 0.59, 0.11],
        [0, 0, 0],
        [0, 0, 0]
      ])
      rm = $M([
        [0, 0, 0],
        [0, 1, 0],
        [0, 0, 1]
      ])

      leftV = lm.multiply(colorVectors['left'])
      rightV = rm.multiply(colorVectors['right'])
      return leftV.add(rightV)
    else
      return colorVectors['center']


  traceRec: (ray, times) ->
    color = new Color(0, 0, 0)

    intersection = @scene.firstIntersection(ray)

    return color unless intersection

    globalAmbient = @scene.globalAmbient
    globalAmbientColor = intersection.getAmbientColor().multiply(globalAmbient)
    color = color.add(globalAmbientColor)

    if RayConfig.illumination
      for light in @scene.lights
        cn = this.illuminate(intersection, ray, light)
        if isNaN(cn.val.elements[0])
          console.log cn
          throw "Uh oh! #{cn}"
        color = color.add(cn)

    return color if times <= 0

    color = color.add(this.reflectAndRefract(intersection, ray, times)) if RayConfig.reflection
    color

  reflectAndRefract: (intersection, ray, times) ->
    f = intersection.figure
    [reflectedRay, refractedRay] = this.specularRays(intersection, ray)
    color = new Color(0, 0, 0)
    if reflectedRay?
      specularReflection = this.traceRec(reflectedRay, times - 1)
      specularReflection = specularReflection.multiplyColor(intersection.getSpecularColor())
      color = color.add specularReflection.multiply(reflectedRay.power)
    if refractedRay?
      specularRefraction = this.traceRec(refractedRay, times - 1)
      specularRefraction = specularRefraction.multiplyColor(intersection.getSpecularColor()) unless ray.isInside() && RayConfig.strongRefraction
      color = color.add specularRefraction.multiply(refractedRay.power)
    color

  specularRays: (intersection, ray) ->
    # the norm n (unit vector)
    n = intersection.getNormal()
    # the point of intersection p
    p = intersection.getPoint()
    #n = obj.norm(pos) #.multiply(-1).toUnitVector()
    n = n.multiply(-1) if ray.isInside()
    # the view direction / input ray i (vector)
    i = p.subtract(ray.line.anchor).toUnitVector()

    n1 = ray.refraction
    n2 = if ray.isInside() then 1 else intersection.figure.reflectionProperties.refractionIndex

    # the angle theta θ = i*n
    i_dot_n = i.dot(n)
    cos_theta_i = -i_dot_n

    # === reflection ===

    # reflection ray r (unit vector)
    reflectionDirection = i.add(n.multiply(2 * cos_theta_i)).toUnitVector()

    # === refraction ===

    # Total reflection!
    if n2 == Infinity
      return [new Ray($L(p, reflectionDirection), n1, ray.power, ray.eye), null]

    ratio = n1 / n2
    sin_theta_t_2 = Math.square(ratio) * (1 - Math.square(cos_theta_i))

    if sin_theta_t_2 > 1
      # Total reflection!
      return [new Ray($L(p, reflectionDirection), n1, ray.power, ray.eye), null]

    cos_theta_t = Math.sqrt(1 - sin_theta_t_2)
    refractionDirection = i.multiply(ratio).add(n.multiply((ratio * cos_theta_i) - cos_theta_t)).toUnitVector()

    # Ok, both reflection and refraction exist => how is the ratio of the power? => frensel approximation
    # Note: we could also use the schlick's approximation which would be a little bit faster but less exact
    r1 = Math.square((n1 * cos_theta_i - n2 * cos_theta_t) / (n1 * cos_theta_i + n2 * cos_theta_t))
    r2 = Math.square((n2 * cos_theta_i - n1 * cos_theta_t) / (n2 * cos_theta_i + n1 * cos_theta_t))
    reflectionPowerRatio = (r1 + r2) / 2
    refractionPowerRatio = 1 - reflectionPowerRatio

    unless 0 <= reflectionPowerRatio <= 1 && 0 <= refractionPowerRatio <= 1
      # Total reflection!
      return [new Ray($L(p, reflectionDirection), n1, ray.power, ray.eye), null]

    unless 0 <= reflectionPowerRatio <= 1 && 0 <= refractionPowerRatio <= 1
      throw "Invalid state: reflectionPowerRatio: #{reflectionPowerRatio}, refractionPowerRatio: #{refractionPowerRatio}"

    return [new Ray($L(p, reflectionDirection), n1, ray.power * reflectionPowerRatio, ray.eye),
            new Ray($L(p, refractionDirection), n2, ray.power * refractionPowerRatio, ray.eye)]


  illuminate: (intersection, ray, light) ->
    f = intersection.figure
    p = intersection.getPoint()

    nv = intersection.getNormal()

    w = ray.line.direction
    wl = light.location.subtract(p).toUnitVector()
    wr = nv.multiply(2).multiply(w.dot(nv)).subtract(w).toUnitVector()

    # Shadow
    shadowRatio = 0
    if RayConfig.shadow
      shadowRatio = light.calculateShadowRatio(p, wl, ray, @scene)
      return new Color(0, 0, 0) if shadowRatio == 1

    ambientColor = intersection.getAmbientColor().multiply(light.intensity.ambient)

    kd = intersection.getDiffuseColor()
    E = light.intensity.diffuse * nv.dot(wl)
    diffuse = kd.multiply(E * light.intensity.diffuse)

    n = f.reflectionProperties.specularExponent
    ks = intersection.getSpecularColor()
    frac = Math.pow(wr.dot(wl), n) / nv.dot(wl)
    spepcularIntensity = frac * E
    spepcularIntensity = 0 if frac < 0 || isNaN(spepcularIntensity)
    specularHighlights = ks.multiply(spepcularIntensity)

    color = ambientColor.add(diffuse).add(specularHighlights)

    raise "Invalid state #{color}" if isNaN(color.val.elements[0])

    color.multiply(1 - shadowRatio)


  castRays: (antialiasing, abstractPixelX, abstractPixelY) ->
    camera = @scene.camera

    # so rays go through the middle of a pixel
    antialiasing_translation_mean = (1 + (1 / antialiasing)) / 2

    arr = []

    if antialiasing == 1
      pixelX = ((abstractPixelX + 0.5) - (camera.width / 2))
      pixelY = ((abstractPixelY + 0.5) - (camera.height / 2)) * -1
      this.calcRayForPixel(arr, camera, pixelX, pixelY)
    else if RayConfig.antialiasingTechnique == 'grid'
      x = [1..antialiasing]
      for i in x
        for j in x
          # translate pixels, so that 0/0 is in the center of the image
          pixelX = ((abstractPixelX + i / antialiasing - antialiasing_translation_mean + 0.5) - (camera.width / 2))
          pixelY = ((abstractPixelY + j / antialiasing - antialiasing_translation_mean + 0.5) - (camera.height / 2)) * -1
          this.calcRayForPixel(arr, camera, pixelX, pixelY)
    else if RayConfig.antialiasingTechnique == 'random'
      x = [1..(antialiasing * antialiasing)]
      z = antialiasing_translation_mean / 2
      for i in x
        # translate pixels, so that 0/0 is in the center of the image
        pixelX = ((abstractPixelX + Math.random(z) - antialiasing_translation_mean + 0.5) - (camera.width / 2))
        pixelY = ((abstractPixelY + Math.random(z) - antialiasing_translation_mean + 0.5) - (camera.height / 2)) * -1
        this.calcRayForPixel(arr, camera, pixelX, pixelY)
    else if RayConfig.antialiasingTechnique == 'jittered'
      x = [1..antialiasing]
      antialiasing_translation_mean = antialiasing_translation_mean + (1 / antialiasing / 2)
      z = 1 / antialiasing
      for i in x
        for j in x
          # translate pixels, so that 0/0 is in the center of the image
          pixelX = ((abstractPixelX + i / antialiasing + Math.random(z) - antialiasing_translation_mean + 0.5) - (camera.width / 2))
          pixelY = ((abstractPixelY + j / antialiasing + Math.random(z) - antialiasing_translation_mean + 0.5) - (camera.height / 2)) * -1
          this.calcRayForPixel(arr, camera, pixelX, pixelY)

    arr

  calcRayForPixel: (arr, camera, pixelX, pixelY) ->
    # calculate point in imagePane in 3D
    p = camera.imageCenter.add(camera.upDirection.multiply(pixelY / camera.height * camera.imagePaneHeight))
    p = p.add(camera.rightDirection.multiply(pixelX / camera.width * camera.imagePaneWidth))

    if ModuleId.C1
      dist = 0.5
      posL = camera.position.add(camera.rightDirection.multiply(-dist)) # -1
      arr.push new Ray($L(posL, p.subtract(posL).toUnitVector()), 1, 1, 'left')
      posR = camera.position.add(camera.rightDirection.multiply(dist)) # 1
      arr.push new Ray($L(posR, p.subtract(posR).toUnitVector()), 1, 1, 'right')
    else
      # vector from camera position to point in image pane
      direction = p.subtract(camera.position)
      # Assume that the camera is not inside an object (otherwise, the refraction index would not be 1)
      arr.push new Ray($L(camera.position, direction), 1, 1, 'center')


this.RayTracer = RayTracer

class ReflectionProperty
  constructor: (@ambientColor, @diffuseColor, @specularColor, @specularExponent, @refractionIndex) ->

class Scene
  constructor: (@camera, @globalAmbient) ->
    @objects = []
    @lights = []

  addLight: (light) ->
    @lights.push light

  addObject: (object) ->
    @objects.push object

  buildOctree: () ->
    if RayConfig.octree
      @octree = new Octree(null, 0)
      depth = Math.min(Math.floor(Math.log(@objects.length) / Math.log(8)), RayConfig.octreeMaxDepth)
      @octree = new Octree(BoundingBox.getBoundingBoxFromObjects(@objects), depth)
      for o in @objects
        @octree.insertObject o

  firstIntersection: (ray) ->
    this.buildOctree() unless @octree

    min = Infinity
    ret = null

    oo = @objects
    oo = @octree.getIntersectionObjects(ray) if RayConfig.octree

    for figure in oo
      i = figure.intersection(ray)
      continue unless i

      dist = i.distance
      if dist != null && dist < min
        ret = i
        min = dist
    ret

class SceneLoader
  constructor: () ->
    # 0. set up the scene described in the exercise sheet (this is called before the rendering loop)
    @scene = this.loadDefaults()

  loadDefaults: () ->
    fieldOfView = 40 / 180 * Math.PI
    #fieldOfView = 30 / 180 * Math.PI
    #camera = new Camera($V([2, 0, 10]), $V([0, 0, -1]), $V([0, 1, 0]), 1, fieldOfView, RayConfig.width,
    camera = new Camera($V([0, 0, 10]), $V([0, 0, -1]), $V([0, 1, 0]), 8.5, fieldOfView, RayConfig.width,
      RayConfig.height)
    #camera = new Camera($V([0, 3, 10]), $V([0, -0.5, -1]), $V([0, 0, 1]), 1, fieldOfView, RayConfig.width, RayConfig.height)

    scene = new Scene(camera, 0.2)
    scene.addLight(this.loadLight())
    #scene.addLight(new Light(new Color(1, 1, 1), $V([10, -10, 10]), new LightIntensity(0, 1, 1)))
    #scene.addLight(new Light(new Color(1, 1, 1), $V([10, 5, 10]), new LightIntensity(0, 1, 1)))
    scene

  loadLight: () ->
    if ModuleId.D2
      direction = $V([0,0,0]).subtract($V([10,10,10])).toUnitVector()
      new Light($V([10, 10, 10]), new Color(1, 1, 1), new LightIntensity(0, 1, 1),
        1, direction)
    else
      new Light($V([10, 10, 10]), new Color(1, 1, 1), new LightIntensity(0, 1, 1))

  loadScene: () ->
    scene = @scene

    if ModuleId.ALT
      this.loadAlternative(scene)
    else if ModuleId.B3
      this.loadB3(scene)
    else if ModuleId.B4
      this.loadB4(scene)
    else if ModuleId.C1
      this.loadC1(scene)
    else if ModuleId.C2
      this.loadC2(scene)
    else if ModuleId.C3
      this.loadC3(scene)
    else if ModuleId.D1
      this.loadD1(scene)
    else
      this.loadOriginal(scene)

    scene

  ### new ReflectionProperty(ambientColor, diffuseColor, specularColor, specularExponent, refractionIndex ###
  loadOriginal: (scene) ->
    # original scene
    scene.addObject(new Sphere($V([0, 0, 0]), 2,
      new ReflectionProperty(new Color(0.75, 0, 0), new Color(1, 0, 0), new Color(1, 1, 1), 32, Infinity)))
    scene.addObject(new Sphere($V([1.25, 1.25, 3]), 0.5,
      new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5)))

    if ModuleId.SP1
      scene.addObject(new Plane($V([0, -1, 0]), $V([1, 1, 0]),
        new ReflectionProperty(new Color(0, 0.75, 0.75), new Color(0, 1, 1), new Color(0.5, 1, 1), 16, Infinity)))

  ### new ReflectionProperty(ambientColor, diffuseColor, specularColor, specularExponent, refractionIndex ###
  loadC2: (scene) ->
    # original scene
    scene.addObject(new Sphere($V([0, 0, 0]), 2,
      new ReflectionProperty(new Color(0.75, 0, 0), new Color(1, 0, 0), new Color(1, 1, 1), 32, Infinity),
      Texture.earth(), NormalMap.earth()))
    scene.addObject(new Sphere($V([1.25, 1.25, 3]), 0.5,
      new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5),
      Texture.moon(), NormalMap.moon()))

    if ModuleId.SP1
      scene.addObject(new Plane($V([0, -1, 0]), $V([1, 1, 0]),
        new ReflectionProperty(new Color(0, 0.75, 0.75), new Color(0, 1, 1), new Color(0.5, 1, 1), 16, Infinity)))


  loadB3: (scene) ->
    # Quadrics

    # axis line, fixed x,y,z axis, radii, reflection properties
    #scene.addObject new Cylinder($V([0, 0, 0]), false, true, false, 2, 0, 0.1,
    #scene.addObject new Cylinder($V([0, 0, 0]), false, true, false, 3, 0, 1,
    scene.addObject new Cylinder($V([0, 0, 0]), false, true, false, 2, 0, 1,
      new ReflectionProperty(new Color(0.75, 0, 0), new Color(1, 0, 0), new Color(1, 1, 1), 32,
        Infinity))
    # center, x,y,z radii, reflection properties
    #scene.addObject new Ellipsoid($V([1.25, 1.25, 3]), 0.5, 0.5, 0.5,
    scene.addObject new Ellipsoid($V([1.25, 1.25, 3]), 0.25, 0.75, 0.5,
      new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16.0,
        1.5))

    if ModuleId.SP1
      scene.addObject(new Sphere($V([2.25, 1.25, 3]), 0.5,
        new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5)))

      scene.addObject(new Sphere($V([-1.25, -1.25, 3]), 0.5,
        new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5)))

      scene.addObject(new Sphere($V([0, 0, 3]), 0.5,
        new ReflectionProperty(new Color(1, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5)))

  loadB4: (scene) ->
    # Boolean operations
    sphere1 = new Sphere($V([1.25, 1.25, 3]), 0.5, null)
    sphere2 = new Sphere($V([0.25, 1.25, 3]), 1, null)
    scene.addObject new MultipleObjectsIntersection(sphere1, sphere2,
      new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5))

    red = new ReflectionProperty(new Color(0.75, 0, 0), new Color(1, 0, 0), new Color(1, 1, 1), 32.0, Infinity)
    yellow = new ReflectionProperty(new Color(0.75, 0.75, 0), new Color(1, 1, 0), new Color(1, 1, 1), 32.0, Infinity)
    scene.addObject new Hemisphere(
      new Sphere($V([0, 0, 0]), 2, red),
      new Plane($V([0, 0, 0]), $V([-1, 0, 1]).toUnitVector(), yellow))


    if ModuleId.SP1
      sphere1 = new Sphere($V([0, 0.5, 3]), 1, null)
      sphere2 = new Sphere($V([0, -0.5, 3]), 1, null)
      m1 = new MultipleObjectsIntersection(sphere1, sphere2, null)
      sphere1 = new Sphere($V([0.5, 0, 3]), 1, null)
      sphere2 = new Sphere($V([-0.5, 0, 3]), 1, null)
      m2 = new MultipleObjectsIntersection(sphere1, sphere2, null)

      mtot = new MultipleObjectsIntersection(m1, m2,
        new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5))
      scene.addObject(mtot)

      cylinder = new Cylinder($V([0, 0, 0]), false, true, false, 2, 0, 1, null)
      sphere1 = new Sphere($V([-2, 1.25, 0]), 1, null)

      #scene.addObject cylinder
      #scene.addObject sphere1
      scene.addObject new MultipleObjectsIntersection(cylinder, sphere1, new ReflectionProperty(new Color(0.75, 0, 0),
        new Color(1, 0, 0), new Color(1, 1, 1), 32, 1.75))

  loadC1: (scene) ->
    scene.addObject(new Sphere($V([0, 0, 0]), 2,
      new ReflectionProperty(new Color(1, 1, 0), new Color(1, 1, 0), new Color(1, 1, 1), 32, Infinity)))
    scene.addObject(new Sphere($V([1.25, 1.25, 3]), 0.5,
      new ReflectionProperty(new Color(0, 1, 1), new Color(0, 1, 1), new Color(1, 1, 1), 32, Infinity)))
    #new ReflectionProperty(new Color(0, 1, 1), new Color(0, 1, 1), new Color(1, 1, 1), 16, 1.5)))

    if ModuleId.SP1
      scene.addObject(new Plane($V([0, -1, 0]), $V([1, 1, 0]),
        new ReflectionProperty(new Color(0, 0.75, 0.75), new Color(0, 1, 1), new Color(0.5, 1, 1), 16, Infinity)))


  loadC3: (scene) ->
    #resp = MeshLoader.loadMeshData("data/mini.obj")
    #resp = MeshLoader.loadMeshData("data/sphere.obj")
    unless ModuleId.SP1
      resp = MeshLoader.loadMeshData("data/sphere.obj")
      sphereMesh1 = new MeshLoader(resp, $V([0, 0, 0]), 2).createMesh()
      sphereMesh1.reflectionProperties = new ReflectionProperty(new Color(0.75, 0, 0), new Color(1, 0, 0), new Color(1, 1, 1), 32, Infinity)
      sphereMesh2 = new MeshLoader(resp, $V([1.25, 1.25, 3]), 0.5).createMesh()
      sphereMesh2.reflectionProperties = new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5)
      scene.addObject sphereMesh1
      scene.addObject sphereMesh2
      scene.buildOctree()
    else
      #resp = MeshLoader.loadMeshData("data/teapot.obj")
      #sphereMesh1 = new MeshLoader(resp, $V([0, 0, 0]), 0.2).createMesh()
      #sphereMesh1.reflectionProperties = new ReflectionProperty(new Color(0.75, 0, 0), new Color(1, 0, 0), new Color(1, 1, 1), 32, Infinity)
      #scene.addObject sphereMesh1
      #resp = MeshLoader.loadMeshData("data/neoSimian-organic.obj")
      #resp = MeshLoader.loadMeshData("data/neoSimian-mech.obj")
      sphereMesh2 = null
      if(Math.random() > 0.5)
        resp = MeshLoader.loadMeshData("data/ateneam.obj")
        sphereMesh2 = new MeshLoader(resp, $V([0, 0, 0]), 0.0004).createMesh()
      else
        resp = MeshLoader.loadMeshData("data/dragon.obj")
        sphereMesh2 = new MeshLoader(resp, $V([0, 0, 0]), 0.2).createMesh()
      sphereMesh2.reflectionProperties = new ReflectionProperty(new Color(0.75, 0, 0), new Color(1, 0, 0), new Color(1, 1, 1), 32, Infinity)
      scene.addObject sphereMesh2

  loadD1: (scene) ->
    for i in [0..9]
      for j in [0..9]
        for k in [0..9]
          scene.addObject new Sphere($V([i - 4.5, j - 4.5, -Math.pow(k, 3)]), 0.25,
            new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5))


  loadAlternative: (scene) ->
    # alternative scene
    c = Color.random()
    scene.addObject(new Sphere($V([-3, 3, 0]), 2,
      new ReflectionProperty(c, c, new Color(1, 1, 1), 32, 1.5)))

    c = Color.random()
    scene.addObject(new Sphere($V([1.25, 1.25, 3]), 0.5,
      new ReflectionProperty(c, c, new Color(0.5, 0.5, 1), 16, 1.5)))

    c = Color.random()
    scene.addObject(new Sphere($V([1.25, -1.25, 3]), 0.5,
      new ReflectionProperty(c, c, new Color(0.5, 0.5, 1), 16, 1.5)))

    c = Color.random()
    scene.addObject(new Sphere($V([-1, -0.75, 3]), 0.5,
      new ReflectionProperty(c, c, new Color(0.5, 0.5, 1), 16, 1.5)))

    scene.addObject(new Sphere($V([2.5, 0, -1]), 0.5,
      new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5)))

    sphere1 = new Sphere($V([0, 0.5, 3]), 1, null)
    sphere2 = new Sphere($V([0, -0.5, 3]), 1, null)
    m1 = new MultipleObjectsIntersection(sphere1, sphere2, null)
    sphere1 = new Sphere($V([0.5, 0, 3]), 1, null)
    sphere2 = new Sphere($V([-0.5, 0, 3]), 1, null)
    m2 = new MultipleObjectsIntersection(sphere1, sphere2, null)

    mtot = new MultipleObjectsIntersection(m1, m2,
      new ReflectionProperty(new Color(0, 0, 0.75), new Color(0, 0, 1), new Color(0.5, 0.5, 1), 16, 1.5))
    scene.addObject(mtot)


this.SceneLoader = SceneLoader

this.trace = (scene, color, pixelX, pixelY) ->
  rayTracer = new RayTracer(color, pixelX, pixelY, scene)
  rayTracer.trace()

class Texture
  constructor: (tgaPath, @specularExponent, @refractionIndex) ->
    @tga = readTGA(tgaPath)

  getPixelColor: (u, v) ->
    # exact pixel values
    u = u * @tga.header.width
    v = v * @tga.header.height

    # integer pixel values surrounding the exact value
    u1 = Math.floor(u)
    v1 = Math.floor(v)
    u2 = Math.min((u1 + 1), @tga.header.width - 1)
    v2 = Math.min((v1 + 1), @tga.header.height - 1)

    # fractional parts of u and v
    frac_u = u - Math.floor(u)
    frac_v = v - Math.floor(v)

    # weight factors of the surrounding pixels
    w1 = (1 - frac_u) * (1 - frac_v)
    w2 = frac_u * (1 - frac_v)
    w3 = (1 - frac_u) * frac_v
    w4 = frac_u * frac_v

    # weighted pixel colors of the surrounding pixels
    c1 = this.getPixelColorSingle(u1, v1).multiply(w1)
    c2 = this.getPixelColorSingle(u2, v1).multiply(w2)
    c3 = this.getPixelColorSingle(u1, v2).multiply(w3)
    c4 = this.getPixelColorSingle(u2, v2).multiply(w4)

    # add them together
    c1.add(c2).add(c3).add c4

  @earth: (prefix) ->
    d = "data/Earth.tga"
    s = if prefix then prefix + d else d
    new Texture(s, 32.0, Infinity)

  @moon: (prefix) ->
    d = "data/Moon.tga"
    s = if prefix then prefix + d else d
    new Texture(s, 16.0, Infinity)

  getPixelColorSingle: (u, v) ->
    id = 3 * (v * @tga.header.width + u)
    r = @tga.image[id + 2] / 255.0
    g = @tga.image[id + 1] / 255.0
    b = @tga.image[id + 0] / 255.0
    new Color(r, g, b)

  noFilter: (u, v) ->
    fu = Math.floor(u * @tga.header.width)
    fv = Math.floor(v * @tga.header.height)
    this.getPixelColorSingle fu, fv
